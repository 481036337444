<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un utilisateur
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un utilisateur
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-3">
                      <label>Nom:</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex: Kawtcha"
                        v-model="form.nom"
                        required
                        :class="{ 'is-invalid': errors.nom }"
                      />
                      <span class="form-text text-danger" v-if="errors.nom">{{
                        errors.nom[0]
                      }}</span>
                    </div>
                    <div class="col-lg-3">
                      <label>Prénoms</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex: Yawavi akou"
                        v-model="form.prenoms"
                        required
                        :class="{ 'is-invalid': errors.prenoms }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.prenoms"
                        >{{ errors.prenoms[0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Ex: example@gmail.com"
                        v-model="form.email"
                        required
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <span class="form-text text-danger" v-if="errors.email">{{
                        errors.email[0]
                      }}</span>
                    </div>
                    <div class="col-lg-3">
                      <label>Sexe</label>
                      <select
                        v-model="form.sexe"
                        id=""
                        class="form-control"
                        required
                        :class="{ 'is-invalid': errors.sexe }"
                      >
                        <option value="">Sélectionnez sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      <span class="form-text text-danger" v-if="errors.sexe">{{
                        errors.sexe[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-3">
                      <label>Role</label>
                      <multiselect
                        v-model="form.role"
                        :options="roleByUser"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un rôle"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                      ></multiselect>
                      <span class="form-text text-danger" v-if="errors.role">{{
                        errors.role[0]
                      }}</span>
                    </div>
                    <div class="col-lg-3" v-if="$route.meta.mode === 'update'">
                      <label>Status</label>
                      <select
                        v-model="form.status"
                        class="form-control"
                        :class="{ 'is-invalid': errors.status }"
                        required
                      >
                        <option value="">Choisir état</option>
                        <option value="1">Activé</option>
                        <option value="0">Désactivé</option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors.status"
                        >{{ errors.status[0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Mot de passe</label>
                      <div class="form-inline-view">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Ex: **********"
                          ref="password"
                          v-model="form.password"
                          :class="{ 'is-invalid': errors.password }"
                        />
                        <span class="input-eyes">
                          <i
                            class="fas fa-eye"
                            v-show="eyeStates === 'open'"
                            @click.prevent.stop="showRegisterPassword"
                          ></i>
                          <i
                            class="fas fa-eye-slash"
                            v-show="eyeStates === 'close'"
                            @click.prevent.stop="hideRegisterPassword"
                          ></i>
                        </span>
                      </div>
                      <span
                        class="form-text text-danger"
                        v-if="errors.password"
                        >{{ errors.password[0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Confirmation</label>
                      <div class="form-inline-view">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Ex: **********"
                          ref="confirmPassword"
                          v-model="form.password_confirmation"
                          :class="{
                            'is-invalid': errors.password_confirmation
                          }"
                        />
                        <span class="input-eyes">
                          <i
                            class="fas fa-eye"
                            v-show="confirmEyeStates === 'open'"
                            @click.prevent.stop="showRegisterConfirmPassword"
                          ></i>
                          <i
                            class="fas fa-eye-slash"
                            v-show="confirmEyeStates === 'close'"
                            @click.prevent.stop="hideRegisterConfirmPassword"
                          ></i>
                        </span>
                      </div>
                      <span
                        class="form-text text-danger"
                        v-if="errors.password_confirmation"
                        >{{ errors.password_confirmation[0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Téléphone</label>
                      <input
                        type="tel"
                        required
                        class="form-control"
                        placeholder="Ex: 99009900"
                        id="phone"
                        v-model="form.telephone_1"
                        v-number-format="form.telephone_1"
                        :class="{ 'is-invalid': errors.telephone_1 }"
                        @change="validPhoneNumber"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.telephone_1"
                        >{{ errors.telephone_1[0] }}</span
                      >
                      <span
                        id="valid-msg"
                        class="text-success"
                        v-show="isValidPhoneNumber && !errors.telephone_1"
                        >✓ Valid</span
                      >
                      <span
                        id="error-msg"
                        class="text-danger"
                        v-show="isInvalidPhoneNumber && !errors.telephone_1"
                        >{{ inValidError }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button type="submit" class="btn btn-primary mr-2">
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
// import intlTelInput from "intl-tel-input";
// import $ from "jquery";
// import utilscript from "intl-tel-input/build/js/utils";

export default {
  name: "Form",
  data() {
    return {
      success: false,
      has_error: false,
      error: "",
      errors: {},
      isValidData: null,

      formattedNum: null,
      isValidPhoneNumber: false,
      isInvalidPhoneNumber: false,
      inValidError: "",
      eyeStates: "open",
      confirmEyeStates: "open"
    };
  },
  computed: {
    ...mapGetters({
      form: "getUserForm",
      roles: "getUserRoles",
      user: "user"
    }),
    roleByUser() {
      return this.filterRoleByUser();
    }
  },
  beforeCreate() {
    if (this.$route.meta.mode === "update") {
      this.$store.dispatch("editUser", this.$route.params.id);
    } else {
      this.$store.dispatch("createUser");
    }
  },
  mounted() {
    const input = document.querySelector("#phone");
    this.formattedNum = this.inputInternational(input);
  },
  methods: {
    validPhoneNumber(e) {
      if (this.checkPhoneNumber(this.formattedNum)) {
        e.className = "is-valid";
        this.isValidPhoneNumber = true;
        this.isInvalidPhoneNumber = false;
      } else {
        e.className = "is-invalid";
        this.inValidError =
          "Numéro de téléphone invalide saisir un numéro correct svp.";
        this.isValidPhoneNumber = false;
        this.isInvalidPhoneNumber = true;
      }
    },
    filterRoleByUser() {
      return this.roles.filter(role => {
        if (this.user.roles[0].name === "admin") {
          return role;
        } else {
          if (this.user.roles[0].name === "superviseur") {
            if (role.name !== "superviseur" && role.name !== "admin") {
              return role;
            }
          }
        }
      });
    },
    save() {
      this.form.telephone_1 = this.checkPhoneNumber(this.formattedNum);
      if (this.$route.meta.mode === "update") {
        this.$store
          .dispatch("updateUser", {
            id: this.$route.params.id,
            form: this.form
          })
          .then(() => {
            Swal.fire({
              title: "Réussie",
              text: "L'utilisateur a été modifié avec succès.",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            this.$router.push(this.$route.meta.back);
          });
      } else {
        this.$store.dispatch("storeUser", this.form).then(() => {
          Swal.fire({
            title: "Réussie",
            text: "L'utilisateur a été enrégistré",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
          this.$router.push(this.$route.meta.back);
        });
      }
    },
    showRegisterPassword() {
      this.$refs.password.type = "text";
      this.eyeStates = "close";
    },
    hideRegisterPassword() {
      this.$refs.password.type = "password";
      this.eyeStates = "open";
    },
    showRegisterConfirmPassword() {
      this.$refs.confirmPassword.type = "text";
      this.confirmEyeStates = "close";
    },
    hideRegisterConfirmPassword() {
      this.$refs.confirmPassword.type = "password";
      this.confirmEyeStates = "open";
    }
  }
};
</script>

<style scoped></style>
